import axios from '@/axios'
const route = 'register/additional_charges'
export default {

    getAll() {
        return axios.get(route)
    },

    updateOrStore(additional_charges) {
        return axios.post(route + '/store_or_update', additional_charges)
    },



}
