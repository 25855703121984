<template>
  <v-container>
   
   <v-card>
    <v-toolbar flat>
          <v-toolbar-title>Encargos Complementares</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="mt-3 mb-3" rounded @click="dialog_params=!dialog_params" color="primary">
            <v-icon small>mdi-file-document-edit-outline</v-icon>
            Parâmetros
            </v-btn>    
        </v-toolbar>
        
   <v-container>
     <template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_params"
      max-width="800px"
    >
     
      <v-card>
        <v-card-title>
          <span class="text-h5">Parâmetros</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-card >
    <v-data-table
      dense
      hide-default-footer
      :headers="headers"
      :items="params"
      :items-per-page="20"
      :loading="loadingTable"
    >
      <template v-slot:item.value="props">
        <v-edit-dialog 
        :return-value.sync="props.item.value" 
          persistent
          large
          cancel-text="CANCELAR"
          save-text="SALVAR"          
        @save="save">
          <div v-if="props.item.unity == 'DIAS'|| props.item.unity == 'QTD'" class="editable">{{ props.item.value }}</div>
          <div v-else class="editable">{{ formatMoney(props.item.value) }}</div>
          
          <template v-slot:input>
           <!-- se for dinheiro   -->
          <div v-if="props.item.unity == 'R$'||props.item.unity == 'hs'">
              <vuetify-money
                  v-model="props.item.value"
                  :clearable="true"
                  valueWhenIsEmpty="0"
                  :options="optionsMoney"
              />
          </div>  
           <!-- se for porcentagem -->
          <div v-if="props.item.unity == '%' ">
              <vuetify-money
                  v-model="props.item.value"
                  :clearable="true"
                  valueWhenIsEmpty="0"
                  :options="{ locale: 'pt-BR', prefix: '', suffix: '%', length: 11,  precision: 2}"
                                />
          </div>  
          <!-- se for  numeros inteiros -->
            <div v-if="props.item.unity == 'DIAS'|| props.item.unity == 'QTD'">
            <vuetify-money
                  v-model="props.item.value"
                  :clearable="true"
                  valueWhenIsEmpty="0"
                  :options="{ locale: 'pt-BR', length: 11,  precision: 0}"
                                />
          </div>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table >
    </v-card>
            

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="dialog_params = false"
          >
            Fechar
          </v-btn>
          <!-- <v-btn
            color="blue darken-1"
            text
            @click=" = false"
          >
            Save
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>


</template>


<v-card class="mt-3">
   <v-simple-table dense class="mt-6">
    <template v-slot:default>
      <thead  class="header_table">
        <tr>
          <td style="font-size: 11px; border:1px solid white" align="center" rowspan="2">ITEM</td>
          <td style="font-size: 11px; border:1px solid white" align="center">ENCARGOS COMPLEMENTARES</td>
          <td style="font-size: 11px; border:1px solid white" align="center" colspan="3">CUSTO MENSAL DO BENEFÍCIO</td>
          <td style="font-size: 11px; border:1px solid white" align="center" colspan="3">DESCONTO</td>
          <td style="font-size: 11px; border:1px solid white" align="center" rowspan="2">CUSTO DO BENEFÍCIO</td>
          <td style="font-size: 11px; border:1px solid white" align="center" rowspan="2" >CUSTO DO BENEFÍCIO(%)</td>
        </tr>
        <tr>
          <td style="font-size: 11px; border:1px solid white" align="center">CONVENÇÃO E DISSIDIO COLETIVO</td>
          <td style="font-size: 11px; border:1px solid white" align="center" >QUANT</td>
          <td style="font-size: 11px; border:1px solid white" align="center" >P UNIT</td>
          <td style="font-size: 11px; border:1px solid white" align="center">SUB-TOTAL</td>
          <td style="font-size: 11px; border:1px solid white" align="center">BASE</td>
          <td style="font-size: 11px; border:1px solid white" align="center">%</td>
          <td style="font-size: 11px; border:1px solid white" align="center">SUB-TOTAL</td>
       </tr>
      </thead>
      <tbody>
        <tr v-for="item in data_taxes" :key="item.name">
          <td align="center">{{ item.item }}</td>
          <td>{{ item.name }}</td>
          <td align="center" >{{ formatMoney(item.quantity) }}</td>
          <td align="center" >{{ formatMoney(item.unity) }}</td>
          <td align="center" >{{ formatMoney(item.subtotal) }}</td>
          <td align="center" >{{ formatMoney(item.base) }}</td>
          <td align="center" >{{ formatMoney(item.percentage) }}</td>
          <td align="center" >{{ formatMoney(item.descont_subtotal) }}</td>
          <td align="center" >{{ formatMoney(item.benefit_cost) }}</td>
          <td align="center" >{{ formatMoney(item.benefit_cost_percentage) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
 </v-card>
 </v-container>
</v-card>  

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>


    

  </v-container>
</template>

<script>
import service from './service'
export default {
  
 
  data() {
    return {
     dialog_params:false,
     loadingTable:true,  
    optionsMoney: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
      snack:false,
      snackText:'',
      snackColor:'',

      loadingTables: false,
      headers: [
        { text: "Parâmetros", align: "start", value: "name", sortable: false },
        { text: "Unidade", align: "end", value: "unity", sortable: false },
        { text: "", align: "end", value: "value", sortable: false },
      ],
      params: [
        { name: "SALÁRIO BASE", unity: "R$", value: 0 },
        { name: "DIAS TRABALHADOS POR MÊS", unity: "DIAS", value: 0 },
        { name: "CUSTO DIÁRIO DE TRANSPORTE (IDA E VOLTA)", unity: "R$",  value: 0 },
        { name: "DESCONTO DE TRANSPORTE", unity: "%",  value: 0 },
        { name: "CUSTO DIÁRIO DA ALIMENTAÇÃO (CAFÉ + ALMOÇO)", unity: "R$",   value: 0 },
        { name: "DESCONTO DA ALIMENTAÇÃO", unity: "%",   value: 0 },
        { name: "CUSTO MENSAL DA CESTA BÁSICA", unity: "R$", value: 0 },
        { name: "QUANTIDADE DE CESTA BÁSICA", unity: "QTD", value: 0 },
        { name: "CUSTO MENSAL DE EPI", unity: "R$", value: 0 },
        { name: "QUANTIDADE DE EPI", unity: "QTD", value: 0 },
        { name: "CUSTO MENSAL DO PLANO DE SAÚDE", unity: "R$", value: 0 },
        { name: "QUANTIDADE DE PLANO DE SAÚDE", unity: "QTD", value: 0 },
        { name: "DESCONTO DE PLANO DE SAÚDE", unity: "%", value: 0 },
        { name: "CUSTO MENSAL DO SEGURO E VIDA", unity: "R$", value: 0 },
        { name: "QUANTIDADE SEGURO E VIDA", unity: "QTD", value: 0 },
        { name: "CUSTO MENSAL DOS EXAMES CLINICOS (ADM, DEM E PERIÓDICO)", unity: "R$",  value: 0 },
        { name: "QUANTIDADE DE EXAMES CLINICOS (ADM, DEM E PERIÓDICO)", unity: "QTD",  value: 0 },
        { name: "HORAS EXTRAS EQUIVALENTES POR MÊS (QUANT x ADICIONAL)", unity: "hs", value: 0  },
        
                        
      ],
      data_taxes:[
        {item:1, name:'VALE TRANSPORTE',  quantity:0, unity:0 , subtotal: 0, base: 0, percentage: 0 ,descont_subtotal: 0, benefit_cost: 0, benefit_cost_percentage:0 },
        {item:2, name:'ALIMENTAÇÃO',      quantity:0, unity:0 , subtotal: 0, base: 0, percentage: 0 ,descont_subtotal: 0, benefit_cost: 0, benefit_cost_percentage:0 },
        {item:3, name:'CESTA BÁSICA',     quantity:0, unity:0 , subtotal: 0, base: 0, percentage: 0 ,descont_subtotal: 0, benefit_cost: 0, benefit_cost_percentage:0 },
        {item:4, name:'EPI',              quantity:0, unity:0 , subtotal: 0, base: 0, percentage: 0 ,descont_subtotal: 0, benefit_cost: 0, benefit_cost_percentage:0 },
        {item:5, name:'PLANO DE SAUDE',   quantity:0, unity:0 , subtotal: 0, base: 0, percentage: 0 ,descont_subtotal: 0, benefit_cost: 0, benefit_cost_percentage:0 },
        {item:6, name:'SEGURO DE VIDA',   quantity:0, unity:0 , subtotal: 0, base: 0, percentage: 0 ,descont_subtotal: 0, benefit_cost: 0, benefit_cost_percentage:0 },
        {item:7, name:'EXAMES CLÍNICOS',  quantity:0, unity:0 , subtotal: 0, base: 0, percentage: 0 ,descont_subtotal: 0, benefit_cost: 0, benefit_cost_percentage:0 },
        {item:8, name:'HORAS EXTRAS',     quantity:0, unity:0 , subtotal: 0, base: 0, percentage: 0 ,descont_subtotal: 0, benefit_cost: 0, benefit_cost_percentage:0 },
        
      ]
    };
  },
  created(){
   this.loadingTable  = true
  service.getAll().then((response)=>{
      if (response.data.length != 0){
        this.data_taxes = response.data
        this.setDataParams()
       
    }
     this.loadingTable  = false
    
  }).catch(()=>{
      this.loadingTable  = false
  })
  },
  methods:{
    setDataParams(){


  // seta quantidade de dias trabalhados 
       this.params[1].value = this.data_taxes[0].quantity  //vale transporte
       this.params[1].value = this.data_taxes[1].quantity  //vale refeicao
     
    //   // seta valor unitario transporte
        this.params[2].value = this.data_taxes[0].unity

    //   // seta valor porcentagem de desconto transporte
       this.params[3].value  = this.data_taxes[0].percentage

    //   //seta valor unitario almoco 
        this.params[4].value  = this.data_taxes[1].unity

    //   // seta valor porcentagem de desconto alimentacao
       this.params[5].value  = this.data_taxes[1].percentage


    //   //seta sexta basica 
        this.params[6].value  = this.data_taxes[2].unity
  
    //   //seta quantidade de sexta basica
    this.params[7].value  = this.data_taxes[2].quantity
 
    //  //seta valor unitario EPI 
    this.params[8].value  = this.data_taxes[3].unity

    //   //seta quantidade de EPI
    this.params[9].value = this.data_taxes[3].quantity
     
    //   // //seta valor unitario plano de saude 
    this.params[10].value  = this.data_taxes[4].unity
     
    //   //seta quantidade de EPI
     this.params[9].value = this.data_taxes[3].quantity
     
    //   //seta valor unitario plano de saude 
    this.params[10].value  = this.data_taxes[4].unity
     
    //   //seta quantidade de plano de saude
    this.params[11].value = this.data_taxes[4].quantity
      
    //   //seta desconto de plano de saude
    this.params[12].value  = this.data_taxes[4].percentage
      
    //   //seta valor unitario seguro de vida
    this.params[13].value = this.data_taxes[5].unity
      
    //   //seta quantidade de seguro de vida
    this.params[14].value = this.data_taxes[5].quantity
      
    //   //seta valor unitario exame clinico
    this.params[15].value  = this.data_taxes[6].unity

    //   //seta quantidade de exame clinico
    this.params[16].value = this.data_taxes[6].quantity
      
    //   //seta quantidade de seguro de vida
    this.params[14].value = this.data_taxes[5].quantity
      
    //   //seta quantidade de horas extra
    this.params[17].value = this.data_taxes[7].quantity

//seta valor desconto base
       this.params[0].value = this.data_taxes[0].base
      
      //seta valor base deconto transporte
       this.data_taxes[1].subtotal = this.data_taxes[1].base 

      //seta valor base deconto transporte
      this.data_taxes[4].subtotal = this.data_taxes[4].base  
     
      this.calculator()

    },
    save(){
      
      // seta quantidade de dias trabalhados 
      this.data_taxes[0].quantity = this.params[1].value  //vale transporte
      this.data_taxes[1].quantity = this.params[1].value  //vale refeicao
     
      // seta valor unitario transporte
      this.data_taxes[0].unity = this.params[2].value  

      // seta valor porcentagem de desconto transporte
      this.data_taxes[0].percentage = this.params[3].value  

      //seta valor unitario almoco 
      this.data_taxes[1].unity = this.params[4].value  

      // seta valor porcentagem de desconto alimentacao
      this.data_taxes[1].percentage = this.params[5].value  


      //seta sexta basica 
      this.data_taxes[2].unity = this.params[6].value  
  
      //seta quantidade de sexta basica
      this.data_taxes[2].quantity = this.params[7].value  
 
     //seta valor unitario EPI 
      this.data_taxes[3].unity = this.params[8].value  

      //seta quantidade de EPI
      this.data_taxes[3].quantity = this.params[9].value 
     
      // //seta valor unitario plano de saude 
      // this.data_taxes[4].unity = this.params[10].value  
     
      //seta quantidade de EPI
      this.data_taxes[3].quantity = this.params[9].value 
     
      //seta valor unitario plano de saude 
      this.data_taxes[4].unity = this.params[10].value  
     
      //seta quantidade de plano de saude
      this.data_taxes[4].quantity = this.params[11].value 
      
      //seta desconto de plano de saude
      this.data_taxes[4].percentage = this.params[12].value 
      
      //seta valor unitario seguro de vida
      this.data_taxes[5].unity = this.params[13].value  
      
      //seta quantidade de seguro de vida
      this.data_taxes[5].quantity = this.params[14].value 
      
      //seta valor unitario exame clinico
      this.data_taxes[6].unity = this.params[15].value  

      //seta quantidade de exame clinico
      this.data_taxes[6].quantity = this.params[16].value 
      
      //seta quantidade de seguro de vida
      this.data_taxes[5].quantity = this.params[14].value 
      
      //seta quantidade de horas extra
      this.data_taxes[7].quantity = this.params[17].value  

      //seta valor unitario de horas extra 
      this.data_taxes[7].unity = this.parseNumber(this.params[0].value) / 220  
      

      
      setTimeout(()=>{
        this.calculator()
        this.saveAll()
      },50)
      

    },
    
    calculator(){
      this.calcCostMonthly()
      
      //seta valor desconto base
      this.data_taxes[0].base = this.params[0].value
      
      //seta valor base deconto transporte
      this.data_taxes[1].base = this.data_taxes[1].subtotal

      //seta valor base deconto transporte
      this.data_taxes[4].base = this.data_taxes[4].subtotal
     


     this.data_taxes.forEach((item)=>{
        
         // desconto
         item.descont_subtotal = this.parseNumber(item.base) * (this.parseNumber(item.percentage)/100)
         
         //calcula custo do beneficio 
         item.benefit_cost =  item.subtotal - item.descont_subtotal
         
         //custo do beneficio
         let base_salary = this.params[0].value
         item.benefit_cost_percentage =  this.parseNumber(item.benefit_cost)/ this.parseNumber(base_salary) * 100
     })
    
          
    },
    calcCostMonthly(){
      this.data_taxes.forEach((item)=>{
         // calcula custo mensal do beneficio
         item.subtotal =  this.parseNumber(item.quantity) * this.parseNumber(item.unity)
      })
    }, 
    saveAll(){
            
             service.updateOrStore(this.data_taxes)
             this.snack = true
             this.snackColor = "success"
             this.snackText = 'Salvo com sucesso'

    },
    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
    formatMoney(number){
      number = this.parseNumber(number) 
      return number.toLocaleString('pt-br',{minimumFractionDigits: 2,maximumFractionDigits:2})
    },
  }
};
</script>

<style scoped>
.editable {
  color: #1976d2;
}

.header_table{
  background: #1976D2;
  color: white;
}

.header_td{
    
}
</style>